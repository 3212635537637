import React from "react"
import { PageProps } from "@/definitions"
import Layout from "@/components/Layout"
import { Seo } from "@/components/common"

const Imprint: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location} title="Impressum">
      <Seo title="Impressum" />
      <div>
        <p>Impressum Angaben gemäß § 5 TMG</p>
        <br/>
        Florian Fritzsche
        <br/>
        Margit-Schramm-Strasse 7
        <br/>
        80639 München
        <br/>
        <br/>
        Vertreten durch:
        <br/>
        Florian Fritzsche 
        <br/>
        <br/>
        Kontakt
        <br/>
        Telefon: 089-021528641
        <br/>
        E-Mail: flobjective@gmail.com
        <br/>
        <br/>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>
        Florian Fritzsche, Margit-Schramm-Strasse 7, 80639 München 
        <br/><br/>Haftungsausschluss: 
        <br/>
        <br/>Haftung für Inhalte<br/>Die Inhalte unserer Seiten
        wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
        Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
        eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
        zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen. 
        <br/><br/>
        Haftung für Links<br/>
        Unser Angebot enthält Links
        zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
        haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
        übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
        jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
        verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.<br/><br/>
        Datenschutz<br/>Die Nutzung unserer Webseite ist in der Regel ohne
        Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die
        Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
        dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der
        Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
        Übersendung von nicht ausdrücklich angeforderter Werbung und
        Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-Mails, vor.
      </div>
    </Layout>
  )
}

export default Imprint
